<template>
  <div class="personal-information">
    <div class="personal-information__header">
      <h4>Personal Information</h4>
      <!-- <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt
      </p> -->
    </div>
    <div class="personal-information__body">
      <!-- <div class="profile-picture">
        <h2>Profile Picture</h2>
        <div class="picture">
          <div class="user-img">
            <img
              src="../../assets/images/profile-pic.webp"
              alt="User Pictures"
            />
          </div>
          <div class="upload-btn">
            <button class="btn">Upload</button>
            <a href="">Remove Profile Picture</a>
          </div>
        </div>
      </div> -->
      <div class="information">
        <h2>Information</h2>
        <form action="">
          <label class="input-label" for="name">Full Name / Pseudonym</label>
          <input id="name" type="text" v-model="updateUserName" />
          <p v-if="nameFieldError" class="error-msg">This field is required</p>
          <label class="input-label" for="email">Email Address</label>
          <input id="email" type="email" v-model="updateEmail"/>
          <p v-if="emailFieldError" class="error-msg">This field is required</p>
          <label class="input-label" for="email">Recovery Email <span>(optional)</span></label>
          <input id="email" type="email" v-model="recoveryEmail"/>
          <label class="input-label" for="website">Website <span>(optional)</span></label>
          <input id="website" type="text" v-model="updateWebsite"/>
        </form>

        <p class="update-msg" v-show="onUpdate">{{ updateMsg }}</p>
        <p class="error-msg" v-show="onError">{{ fieldErr }}</p>
        <div class="update-name-btn">
          <div v-if="loading" class="loader">
            <the-loader></the-loader>
          </div>
          <button
            class="update-profile-btn btn-primary disable"
            @click="updateInformation"
          >
            Update Profile
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLoader from "../../BaseComponents/TheLoader.vue";
export default {
  components: {
    TheLoader,
  },
  data() {
    return {
      loading: false,
      updateUserName: "",
      onUpdate: false,
      updateMsg: "",
      updateEmail: "",
      nameFieldError: false,
      emailFieldError: false,
      fieldErr: "",
      onError: false,
      recoveryEmail: "",
      birthday: ""
    };
  },
  created() {
      this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      try {
        const response = await this.$store.dispatch("profile/fetchProfileInfo");
        if (response.status === 200) {
          this.updateUserName = response.data.profile.full_name;
          this.updateEmail = response.data.email;
          this.recoveryEmail = response.data.secondary_email;
          // this.updateWebsite = response.data.website;
          // this.birthday = response.data.birthday;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateInformation() {
      if(this.updateUserName == ""){
        this.nameFieldError = true;
        this.emailFieldError = false;
        return false;
      }
      if(this.updateEmail == ""){
        this.emailFieldError = true;
        this.nameFieldError = false;
        return false
      }
      this.loading = true;
      let payload = {
        email: this.updateEmail,
        secondary_email: this.recoveryEmail,
        profile: {
          full_name: this.updateUserName,
        },
      };
      try {
        const response = await this.$store.dispatch(
          "profile/updateProfileInfo",
          payload
        );
        if (response.status === 200) {
          this.onUpdate = true;
          this.onError = false;
          this.updateMsg = "Information has been updated";
        }
      } catch (err) {
        this.onError = true;
        this.onUpdate = false;
        this.fieldErr = err.response.data.email[0];
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@include reset-css;

.personal-information {
  background-color: $color-white;
  margin-top: 1.5rem;
  &__header {
    border-bottom: 0.24rem solid rgba(119, 119, 119, 0.123);
    padding: 1.5rem 4rem;
    h4 {
      font-size: 1.8rem;  
      font-family: $font-secondary-bold;
      margin-bottom: 0;
    }
    p {
      font-size: 1.6rem;
      font-family: $font-secondary;
      padding: 0.5rem 0;
    }
  }
  &__body {
    padding: 2rem 4rem;
    .profile-picture {
      h2 {
        font-size: 1.6rem;
        font-family: $font-secondary-bold;
      }
      .picture {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 1.5rem 0.4rem;
        .user-img {
          width: 11.5rem;
          img {
            width: 100%;
          }
        }
        .upload-btn {
          display: flex;
          flex-direction: column;
          margin: 0 2rem;
          .btn {
            background-color: $color-primary;
            color: $color-white;
            height: 4.2rem;
            font-size: 1.6rem;
            font-family: $font-secondary;
            border: none;
            margin: 1rem 0;
            cursor: pointer;
          }
          a {
            font-size: 1.6rem;
            font-family: $font-secondary;
            opacity: 0.5;
            color: $color-black;
          }
        }
      }
    }
    .information {
      margin-top: 3rem;
      h2 {
        font-size: 1.6rem;
        font-family: $font-secondary-bold;
      }
      form {
        margin-top: 2rem;
        .input-label {
          font-size: 1.6rem;
          font-family: $font-secondary;
          margin: 1.5rem 0;
          display: block;
          span {
            color: grey;
          }
        }
        input {
          @include respond(laptop-small) {
            width: 100%;
          }
          width: 49rem;
          background-color: $color-light-grey-2;
          font-size: 1.6rem;
          font-family: $font-secondary;
          padding: 1rem 2rem;
          outline: none;
          border: 0.22rem solid #70707033;
          margin-bottom: 1rem;
        }
        .ant-calendar-picker {
          width: 49rem;
          background-color: $color-light-grey-2;
          margin-bottom: 1rem;
          @include respond(laptop-small) {
            width: 100%;
          }
          .ant-calendar-picker-input {
            font-size: 1.6rem;
            font-family: $font-secondary;
            padding: 1rem 2rem;
            outline: none;
            border: 0.22rem solid #70707033;
            margin-bottom: 0;
            box-shadow: none;
          }
          .anticon {
            right: 2.2rem;
          }
        }
      }
      .update-msg {
        margin: 0;
        font-size: 1.6rem;
        font-family: $font-secondary;
        color: $color-primary;
      }
      .error-msg {
        margin: 0;
        font-size: 1.6rem;
        font-family: $font-secondary;
        color: red;
      }
      .update-name-btn {
        position: relative;
        width: fit-content;
        
        .loader {
          position: absolute;
          z-index: 2222;
          height: 100%;
          left: 0;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.514);
          .ant-spin {
            color: $color-white;
            height: 100%;
            .anticon {
              color: $color-white;
              height: 100%;
              .anticon-spin {
                color: $color-primary;
                height: 100%;
                font-size: 3rem;
              }
            }
          }
        }
        .update-profile-btn {
          margin-top: 1.8rem;
        }
      }
    }
  }
}
</style>
